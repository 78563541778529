import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Nav.scss";
import logo from "../images/logo.png";
import { resumeLink } from "../data/data";

const Nav = (props) => {
  Nav.propTypes = {
    view: PropTypes.string,
    position: PropTypes.number,
    scrollTo: PropTypes.func,
  };
  const [state, setState] = useState({
    showDropdown: false,
  });

  useEffect(() => {
    setState({ ...state, showDropdown: false });
  }, [props.position]);

  useEffect(() => {
    const handleResize = () => {
      setState({ ...state, showDropdown: false });
    };
    window.addEventListener("resize", handleResize);
  }, []);
  const toggleDropdown = () => {
    setState({ ...state, showDropdown: !state.showDropdown });
  };
  const handleClick = () => {
    if (state.showDropdown) {
      toggleDropdown();
    }
  };
  return (
    <div
      className={`Nav ${props.view === "top" ? "" : "pop"} ${
        state.showDropdown ? "expand pop" : ""
      }`}
    >
      <div className="nav-left">
        <a href="/">
          <img src={logo} />
        </a>
      </div>
      <i
        className={`fa fa-bars ${state.showDropdown ? "active" : ""}`}
        onClick={() => toggleDropdown()}
      />
      <div className="nav-right">
        <a onClick={() => props.scrollTo("about")}>
          <div
            className={`nav-item about ${
              props.view === "about" ? "selected" : ""
            }`}
          >
            ABOUT
          </div>
        </a>
        <a onClick={() => props.scrollTo("skills")}>
          <div
            className={`nav-item skills ${
              props.view === "skills" ? "selected" : ""
            }`}
          >
            SKILLS
          </div>
        </a>
        <a onClick={() => props.scrollTo("projects")}>
          <div
            className={`nav-item projects ${
              props.view === "projects" ? "selected" : ""
            }`}
          >
            PROJECTS
          </div>
        </a>
        <a onClick={() => props.scrollTo("contact")}>
          <div
            className={`nav-item contact ${
              props.view === "contact" ? "selected" : ""
            }`}
          >
            CONTACT
          </div>
        </a>
        <a href={resumeLink} target="_blank" rel="noreferrer">
          <div className="nav-item resume">RESUME</div>
        </a>
      </div>
      <div className="nav-dropdown">
        <a href="#about" onClick={() => handleClick()}>
          <div
            className={`nav-item about ${
              props.view === "about" ? "selected" : ""
            }`}
          >
            ABOUT
          </div>
        </a>
        <a href="#skills" onClick={() => handleClick()}>
          <div
            className={`nav-item skills ${
              props.view === "skills" ? "selected" : ""
            }`}
          >
            SKILLS
          </div>
        </a>
        <a href="#projects" onClick={() => handleClick()}>
          <div
            className={`nav-item projects ${
              props.view === "projects" ? "selected" : ""
            }`}
          >
            PROJECTS
          </div>
        </a>
        <a href="#contact" onClick={() => handleClick()}>
          <div
            className={`nav-item contact ${
              props.view === "contact" ? "selected" : ""
            }`}
          >
            CONTACT
          </div>
        </a>
        <a
          href={resumeLink}
          target="_blank"
          rel="noreferrer"
          onClick={() => handleClick()}
        >
          <div className="nav-item resume">RESUME</div>
        </a>
      </div>
    </div>
  );
};

export default Nav;
