import "./index.scss";
import arrow from "../../../images/arrow.png";
import ProjectItem from "./ProjectItem";

import campfire from "../../../images/projects/campfire.png";
import inquizitorapp from "../../../images/projects/inquizitorapp.png";
import scheduler from "../../../images/projects/scheduler.png";
import randomizer from "../../../images/projects/randomizer.png";
import tweeter from "../../../images/projects/tweeter.png";
import tinyapp from "../../../images/projects/tinyapp.png";
import pomomilk from "../../../images/projects/pomomilk.png";
import visualizer from "../../../images/projects/visualizer.png";
import yelpcamp from "../../../images/projects/yelpcamp.png";
import werdle from "../../../images/projects/werdle.png";
import speakeasy from "../../../images/projects/speakeasy.png";
import portfolio from "../../../images/projects/portfolio.png";

const Projects = () => {
  const projectContent = [
    {
      title: "Campfire",
      description: "A web app for creating course Q&A discussion boards.",
      technologies: [
        "React",
        "Node.js",
        "Express",
        "PostgreSQL",
        "Sass"
      ],
      project: "https://github.com/ahhreggi/campfire",
      background: campfire
    },
    {
      title: "InquizitorApp",
      description: "A web app for generating and sharing quizzes.",
      technologies: [
        "Node.js",
        "Express",
        "PostgreSQL",
        "jQuery",
        "HTML",
        "Sass",
        "Bootstrap 4"
      ],
      project: "https://github.com/ahhreggi/inquizitor-app",
      background: inquizitorapp
    },
    {
      title: "Pomomilk",
      description: "An interactive pomodoro timer.",
      technologies: [
        "React",
        "Redux",
        "Sass"
      ],
      project: "https://github.com/ahhreggi/pomomilk",
      background: pomomilk
    },
    {
      title: "Werdle",
      description: "A replayable Wordle clone.",
      technologies: [
        "React",
        "Sass"
      ],
      project: "https://github.com/ahhreggi/werdle",
      background: werdle
    },
    {
      title: "Speakeasy",
      description: "A collection of thought-provoking conversation questions.",
      technologies: [
        "React",
        "Sass"
      ],
      project: "https://speakeasy.ahhreggi.ca",
      background: speakeasy
    },
    {
      title: "ahhreggi.ca",
      description: "My personal portfolio website.",
      technologies: [
        "React",
        "Sass"
      ],
      project: "",
      background: portfolio
    },
    {
      title: "Interview Scheduler",
      description: "A web app for scheduling interviews.",
      technologies: [
        "React",
        "Node.js",
        "Express",
        "PostgreSQL",
        "Sass"
      ],
      project: "https://github.com/ahhreggi/scheduler",
      background: scheduler
    },
    {
      title: "randomizer.ca",
      description: "A collection of random generator tools.",
      technologies: [
        "Angular",
        "Node.js",
        "Express",
        "Bootstrap 4"
      ],
      project: "https://github.com/ahhreggi/randomizer",
      background: randomizer
    },
    {
      title: "The Joy of Tweeting",
      description: "A Twitter clone/Bob Ross lorem ipsum generator.",
      technologies: [
        "Node.js",
        "Express",
        "jQuery",
        "HTML",
        "Sass"
      ],
      project: "https://github.com/ahhreggi/tweeter",
      background: tweeter
    },
    {
      title: "TinyApp",
      description: "A web app for making tiny URLs.",
      technologies: [
        "Node.js",
        "Express",
        "Bootstrap 4"
      ],
      project: "https://github.com/ahhreggi/tinyapp",
      background: tinyapp
    },
    {
      title: "JSON Table Visualizer",
      description: "A web app for converting Leetcode database I/O data into plain text tables.",
      technologies: [
        "HTML",
        "jQuery",
        "Bootstrap 4"
      ],
      project: "https://github.com/ahhreggi/json-table-visualizer",
      background: visualizer
    },
    {
      title: "YelpCamp",
      description: "A web app for publishing crowd-sourced reviews about campgrounds.",
      technologies: [
        "Node.js",
        "Express",
        "MongoDB",
        "Bootstrap 5",
        "Passport.js"
      ],
      project: "https://github.com/ahhreggi/YelpCamp",
      background: yelpcamp
    },
    {
      title: "Lucille Bot",
      description: "My personal Discord bot.",
      technologies: [
        "Node.js",
        "Discord.js"
      ],
      project: "https://github.com/ahhreggi/lucille-bot"
    }
  ];
  const projects = projectContent.map((project, index) => {
    return (
      <ProjectItem
        key={index}
        title={project.title}
        description={project.description}
        technologies={project.technologies}
        project={project.project}
        background={project.background}
      />
    );
  });
  return (
    <div className="Projects view">
      <section>
        <div className="section-title">
          <img src={arrow} />
          Projects
          <div className="line" />
        </div>
        <div className="section-content">
          {projects}
        </div>
        <div className="gh-link">
          <a className="code" href="https://github.com/ahhreggi" target="_blank" rel="noreferrer">
            <li>view more projects on GitHub</li>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Projects;