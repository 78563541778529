import PropTypes from "prop-types";
import "./Hero.scss";
import Button from "../Button";

const Hero = (props) => {
  Hero.propTypes = {
    scrollToContact: PropTypes.func
  };
  return (
    <div className="Hero view">
      <section>
        <div className="greeting code">
          Hey there, my name is
        </div>
        <div className="name">
          Maria Regina Sirilan.
        </div>
        <div className="role">
          I build and design things for the web.
        </div>
        <div className="description">
          <p>
            I&apos;m a software engineer based in Calgary with a love for gaming and a special interest in understanding the influence of technology & design on human psychology.
          </p>
        </div>
        <Button text="Say Hello" onClick={() => props.scrollToContact()} />
      </section>
    </div>
  );
};

export default Hero;