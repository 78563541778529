import "./About.scss";
import arrow from "../../images/arrow.png";
import profile from "../../images/profile.png";

const About = () => {
  return (
    <div className="About view">
      <section>
        <div className="section-title">
          <img src={arrow} />
          <div className="title">About Me</div>
          <div className="line" />
        </div>
        <div className="section-content">
          <div className="picture">
            <img src={profile} />
          </div>
          <div className="bio">
            <p>
              Hi, I&apos;m Maria Regina, but most people just call me Reggi (with no e!). My venture into web development started when I was 8 years old, using site builders to create pages for my favorite games. After taking an intro programming course in my 2nd year of university purely out of curiosity, I developed a strong passion for coding, sharing that knowledge with others, and finding ways to incorporate it into everyday life.
            </p>
            <p>
              As I continued to expand my skill set, I&apos;ve had the privilege of teaching students at various levels as both a university TA and youth coding instructor. I even established an online presence as a full-time video game streamer, leveraging my creativity and aptitude for learning to build a personal brand and grow on social media. Today I&apos;m building all kinds of cool stuff as a software engineer at <a href="https://bench.co" target="_blank" rel="noreferrer">Bench</a>. I love trying to understand how technology and design interact with human psychology, believing that this knowledge can be used to improve user experiences as well as help students learn.
            </p>
            <div className="technologies">
              Technologies I&apos;ve worked with recently include:
              <ul className="code">
                <li>React</li>
                <li>Node.js</li>
                <li>JavaScript (ES6+)</li>
                <li>Express</li>
                <li>TypeScript</li>
                <li>Amazon Web Services</li>
                <li>GraphQL</li>
                <li>SQL</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;