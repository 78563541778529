import { useState, useEffect, useRef } from "react";
import "./App.scss";
import Nav from "./Nav";
import Hero from "./sections/Hero";
import About from "./sections/About";
import Skills from "./sections/Skills";
import Projects from "./sections/Projects";
import Contact from "./sections/Contact";

const App = () => {
  const [state, setState] = useState({
    view: "top",
    position: 0
  });

  const refs = {
    about: useRef(null),
    skills: useRef(null),
    projects: useRef(null),
    contact: useRef(null)
  };

  const scrollTo = (ref) => {
    refs[ref].current.scrollIntoView();
  };

  const handleScroll = () => {
    const pos = window.pageYOffset;
    let view;
    if (pos < 10) {
      view = "top";
    }
    // } else if (pos > 4700) {
    //   view = "contact";
    // } else if (pos > 2900) {
    //   view = "projects";
    // } else if (pos > 1100) {
    //   view = "skills";
    // } else if (pos > 300) {
    //   view = "about";
    // } else {
    //   view = null;
    // }
    setState({ ...state, view: view, position: pos });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className="App">
      <div className="nav-container">
        <Nav
          view={state.view}
          position={state.position}
          scrollTo={scrollTo}
        />
      </div>
      <Hero scrollToContact={() => scrollTo("contact")} />
      <div id="about" ref={refs.about} />
      <About />
      <div id="skills" ref={refs.skills} />
      <Skills />
      <div id="projects" ref={refs.projects} />
      <Projects />
      <div id="contact" ref={refs.contact} />
      <Contact />
      <div className={`scroller ${state.position < 400 ? "hide" : ""}`} onClick={() => window.scrollTo({top: 0})}>
        <div className="arrow" />
      </div>
      <footer>
        &copy; <span>2022 Maria Regina Sirilan</span>
      </footer>
    </div>
  );
};

export default App;
